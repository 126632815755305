<template>
  <div>
    <h2>* 사용자 내건물 리스트</h2>
    <b-card>
      <b-row>
        <b-col md="6" sm="8" />
        <b-col md="6" class="mb-1">
          <label for="filterInput">검색</label>
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-table
            :current-page="currentPage"
            :fields="adminBuildHeader"
            :items="adminBuildHist"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          />
          <div class="tbFooter">
            <div>
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="adminBuildHist.length"
                aria-controls="my-table"
              />
            </div>
            <div class="regBtnArea">
              <b-button variant="warning" class="mr-1" @click="goList">
                돌아가기
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue';

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BCard,
    BButton,
    BTable,
    BPagination,
    BRow,
  },
  data() {
    return {
      selected: [],
      currentPage: 1,
      perPage: 20,
      filter: null,
      filterOn: [],
    };
  },
  computed: {
    adminBuildHeader() {
      return this.$store.state.admin.adminBuildHeader;
    },
    adminBuildHist() {
      return this.$store.state.admin.adminBuildHist;
    },
  },
  async created() {
    await this.$store.dispatch('admin/FETCH_ADMIN_BUILD_HIST', {
      userId: this.$route.query.userId,
    });
  },
  methods: {
    goList() {
      this.$router.push(`/admin-dtl?userId=${this.$route.query.userId}`);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.tbFooter {
  text-align: center;
  padding: 30px 0;
}

.tbFooter div {
  display: inline-block;
}

.regBtnArea {
  float: right;
}
</style>
